<script setup lang="ts">
const props = defineProps<{
  isLoading: boolean;
}>();
</script>

<template>
  <lukso-modal :is-open="props.isLoading ? true : undefined" size="small">
    <div
      class="flex flex-col items-center text-center justify-center bg-black p-5 rounded-8"
    >
      <div class="animate-spin">⏳</div>
      <div>Working...</div>
    </div>
  </lukso-modal>
</template>
